import React from 'react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { IonRow, IonCol, IonButton, IonAlert, IonGrid } from '@ionic/react';
import Box, { BoxHeader } from '../../components/box';
import OrderContent from '../../components/orderContent';
import { createNewBasketInstance } from '../../lib/basket';
import Basket from '../../lib/basket';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import moment from '../../lib/moment';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import AmountPaid from '../../components/amountPaid';
import { OrderTypeEnum } from '../../enums/order-type.enum';
import './index.css';

class HistoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const orderArr = location?.state?.order;
    const now = moment();
    let cutoffTime = orderArr ? orderArr.cutoff_time : now;
    let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
    const utcOffset = cutoffTimeMoment.utcOffset();
    cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
    if (now.isBefore(cutoffTimeMoment)) {
      if (orderArr.status !== 'REFUNDED') {
        this.setState({
          isCancel: true,
          orderId: orderArr.id,
          restaurantId: orderArr.restaurant_id,
        });
      }
    }
  }

  backHandler = () => {
    this.setState({ orderId: null, isCancel: false, restaurantId: null });
    forwardTo('/dashboard');
    forwardTo('/history', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    let splitedStr = str.split(' ').splice(2).join(' ');
    return splitedStr;
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  drawContentTitle = (__, id, orderType) => {
    switch (orderType) {
      case OrderTypeEnum.OUTPOST_DROP_OFF:
        return (
          <Title>
            {__('Drop Point')} {__('Order')} #{id}.
          </Title>
        );
      case OrderTypeEnum.DELIVERY:
        return (
          <Title>
            {__('Delivery')} {__('Order')} #{id}.
          </Title>
        );
      case OrderTypeEnum.TABLE:
        return (
          <Title>
            {__('At Table')} {__('Order')} #{id}.
          </Title>
        );
      case OrderTypeEnum.COLLECTION:
        return (
          <Title>
            {__('Click and collect')} #{id}.
          </Title>
        );

      default:
        return (
          <Title>
            {__(orderType)} #{id}.
          </Title>
        );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Collect from')}:</SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Collect at')}:</SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Order placed on')}:</SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </IonCol>
          </IonRow>
          {!isOldLayoutOfConfirmationScreens ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText className="order-header">{__('Payment Method')}:</SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {visaEndingDrawText(
                    basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                  )}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Delivery from')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Delivery at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Order placed on')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
            </IonCol>
          </IonRow>
          {order && order.amount_paid > 0 ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText tag="strong" className="bold">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {visaEndingDrawText(
                    basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                  )}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
          {order && order.status === 'REFUNDED' ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText className="order-header">{__('Order Status')}:</SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {'Refunded'}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : (
            ''
          )}
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Drop-off at')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {order.restaurant_name}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Order Placed')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText tag="strong" className="bold">
                {__('Drop-off Time')}:
              </SmallText>
            </IonCol>
            <IonCol className="righted">
              <SmallText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </SmallText>
            </IonCol>
          </IonRow>
          {order && order.amount_paid > 0 ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText tag="strong" className="bold">
                  {__('Payment Method')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {visaEndingDrawText(
                    basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                  )}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : null}
          {order && order.status === 'REFUNDED' ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <SmallText tag="strong" className="bold">
                  {__('Order Status')}:
                </SmallText>
              </IonCol>
              <IonCol className="righted">
                <SmallText color="primary" className="thiner-text">
                  {'Refunded'}
                </SmallText>
              </IonCol>
            </IonRow>
          ) : (
            ''
          )}
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Ordered at')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{order.restaurant_name}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Table number')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{order.table_name}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Order Placed')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>{moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}</SmallText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <SmallText className="order-header">{__('Payment Method')}:</SmallText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <SmallText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </SmallText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };

  render() {
    const { __, location, cards } = this.props;
    const order = location.state.order;
    const { id, status } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderCompletePage = false;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    return (
      <Loading>
        <Layout
          headerTitle={__('History')}
          backHandler={this.backHandler}
          hideSecondToolbar={true}
          noPadding={true}
        >
          <div className="scrollable-y history-details-wrapper">
            {!isOldLayoutOfConfirmationScreens ? (
              <>
                <div className="order-status">{__(status)}</div>
                <Spacer size={2} />
                <Title>{this.drawContentTitle(__, id, orderTypeTitle)}</Title>
                <Spacer size={3} />
                <IonGrid className="okx-box-header-grid">
                  <NormalText className="order-header">{__('Order Details')}</NormalText>
                  <Spacer size={2} />
                  <>
                    {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
                    <Spacer size={3} />
                    <OrderContent basketInstance={basketInstance} type="orderHistory" />
                    <AmountPaid order={order} cards={this.props.cards} />
                  </>
                </IonGrid>
                {orderType !== 'Click & Collect' &&
                ['PAID', 'PARSED', 'NEW', 'new'].indexOf(order.status) !== -1 &&
                this.state.isCancel &&
                hasCancelOrder ? (
                  <IonButton
                    expand="block"
                    fill="clear"
                    className="link underlined"
                    color="tertiary"
                    onClick={() => this.handleCancelOrderModal(true)}
                  >
                    {__('Cancel this order')}
                  </IonButton>
                ) : null}
              </>
            ) : (
              <>
                <div className="order-status">{__(status)}</div>
                {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
                {isOldLayoutOfConfirmationScreens ? (
                  <div className="order-status-payment-method">
                    <IonCol size="5" className="mr-10">
                      <SmallText tag="strong" className="bold">
                        {__('Payment Method')}:
                      </SmallText>
                    </IonCol>
                    <IonCol>
                      <SmallText color="primary" className="thiner-text">
                        {visaEndingDrawText(paymentMetod)}
                      </SmallText>
                    </IonCol>
                  </div>
                ) : null}
                <Spacer size={1} />
                <Box>
                  <BoxHeader>
                    {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
                  </BoxHeader>
                  <Spacer size={2} />
                  <NormalText className="bold">{__('Items Ordered')}</NormalText>
                  <OrderContent basketInstance={basketInstance} type="orderHistory" />
                  <AmountPaid order={order} cards={cards} />
                </Box>
              </>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  return {
    cards: orders.cards || [],
    lastOrder,
    restaurants: restaurants || [],
    cancelOrderModal,
  };
};

export default connect(mapStateToProps)(withTranslation(HistoryDetails));
