import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate';
import { SmallText } from '../../components/common';
import stamp_image from '../../assets/images/stamp-icon.png';

export class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIndex: null,
    };
  }

  render() {
    const { __, available_balance } = this.props;
    return (
      <div className="absolute-content loyalty-content">
        <div className="stamps">
          {Array(6)
            .fill(null)
            .map((item, image_index) => stamp_image)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    index < available_balance
                      ? 'stamp-photo redeemable-stamp-photo'
                      : 'stamp-photo locked-stamp-photo'
                  }
                >
                  <img src={item} />
                </div>
              );
            })}
        </div>
        <div className="flex-min stamps-text">
          <SmallText className="stamps-info">
            {__(
              "Buy six coffees, get yout seventh on us. Your full stamp card will be redeemable from the 'My rewards' section.",
            )}
          </SmallText>
        </div>
      </div>
    );
  }
}

export default withTranslation(Points);
