import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption, validateProfileData } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import menuImage from '../../assets/images/logo/wh-logo.png';
import PromoBar from '../../components/promoBar';
import crowdfundingLogo from '../../assets/images/logo/crowdcube-logo.png';
import { NormalText, SmallText, Spacer, Subtitle } from '../../components/common';
import { warning } from 'ionicons/icons';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
    };
  }

  render() {
    const config = getConfig();
    const { hasOrdering, hasCampaignManager } = config.appType;
    const { hasInvest, bannerInvestTitle, bannerInvestText, bannerInvestFooterText } = config.invest;
    const { __, screenName, vouchers,profile,auth} = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const valid = validateProfileData(profile).isValid;

    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div className="absolute-content dash-layout"></div>
        {hasInvest &&
          <PromoBar
            __={__}
            title={__(bannerInvestTitle)}
            text={
              <>
                <SmallText >
                  {__(bannerInvestText)}
                </SmallText>
              </>
            }
            footerText={__(bannerInvestFooterText)}
            footerImage={crowdfundingLogo}
            onClick={() => forwardTo('/invest')}
            className={'crowdfunding-promo-bar'}
          />}

          {(!valid && auth.loggedIn && getConfig().flags.hasVerificationPopup) && 
                  <div onClick={()=>forwardTo('/account',{openVerificationPopup:true})} className='unverified-popup' >
                  <div className='unverified-popup-icon'>
                    <IonIcon icon={warning} />
                  </div>
                  <div>
                    <Subtitle className='block'>{__('Verification Required:')}</Subtitle>
                    <NormalText>{__(`To redeem your free coffee voucher please make sure your account has been verified in the ‘My Account’ page.`)}</NormalText>
                  </div>
                </div>
          
          }

        <IonCard className="dash-card">
          <IonCardHeader>
            <IonCardTitle className="ion-text-center" color="tertiary">
              <div className="dash-icon">
                <img src={menuImage} />
              </div>
              <span className="dash-welcome-text">
                <span>{__('Welcome back')}</span> <span>{'\n' + screenName}</span>
              </span>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="dash-menu">
            <IonList>
              {hasOrdering ? (
                <IonItem
                  className="clickable round-button white-button"
                  onClick={() => forwardTo('/delivery-options')}
                >
                  <div tabIndex="-1"></div>
                  <IonLabel>{__('Start New Order')}</IonLabel>
                </IonItem>
              ) : null}
            </IonList>
          </IonCardContent>
        </IonCard>
        {/* {hasCampaignManager ? <PromoBar type={__('info')} vouchers={voucherRes} /> : null} */}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers,auth } = state.profile;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}.`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
    profile,
    auth
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
