import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { getRestaurants } from '../../store/restaurants/actions';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { sendFeedback, setCommonModal } from '../../store/actions';
import { FieldError, Title, SmallText, Spacer } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';

const { SelectOption } = Mobiscroll;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: {
        type: 'select',
        required: true,
        invalidValue: null,
      },
    };
  }

  resetState = () => ({
    food: 0,
    service: 0,
    customerService: true,
    techSupport: false,
    commentService: '',
    commentTech: '',
    selectedRestaurant: null,
    formErrors: {},
  });

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          className="star"
          color="secondary"
          onClick={() => this.setValue(name, value)}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          className="star"
          color="medium"
          onClick={() => this.setValue(name, value)}
        />
      );
    }
  };

  handleFeedback = () => {
    const config = this.state.customerService
      ? { ...this.formConfig }
      : { food: this.formConfig.food, service: this.formConfig.service };
    let formErrors = validateForm(config, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };

  componentDidMount() {
    if (this.props.restaurants && this.props.restaurants.length > 0) return;
    this.props.dispatch(getRestaurants());
  }

  selectRestaurant = (event, data) => {
    const selectedRestaurantId = data.getVal();
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    this.setState({ [field]: value });
  }

  setCheckboxValue(field, value) {
    const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
    this.formConfig.selectedRestaurant.required = field !== 'customerService';
    this.setState({ [field]: value, [decheckedKey]: !value });
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.is_published)) {
        if (store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });
    return [{ text: __('Select Restaurant'), value: null }, ...arrForSelect];
  };

  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { techSupport, commentService, commentTech, formErrors, customerService } = this.state;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout headerTitle={__('Feedback.')} hideSecondToolbar={true}>
          <div className="heading-wrapper">
            <Title>{__('Feedback.')}</Title>
            <SmallText color="primary" className="heading">
              {__('Please tell us about your experience.')}
            </SmallText>
          </div>
          <Spacer size={1} />
          <div className="frm-wrapper">
            <div className="inline-input location-select">
              <label
                className="select-picker-label select-picker-label--feedback"
                htmlFor="demo-non-form"
              >
                {__('Location')}
              </label>

              <SelectOption
                className="select-picker-input--feedback"
                display="center"
                onSet={(e, a) => this.selectRestaurant(e, a)}
                data={this.formatDataForSelect(stores)}
                label="Location"
                value={this.state.selectedRestaurant}
                inputStyle="box"
                placeholder={__('Select Restaurant')}
                setText={__('OK')}
                cancelText={__('Cancel')}
              />
            </div>
            {formErrors.selectedRestaurant ? (
              <FieldError className="field-error pad10" value={__(formErrors.selectedRestaurant)} />
            ) : null}
            <div className="box-holder box-holder--feedback box-holder-top">
              <IonList>
                <div>
                  <IonItem lines="full">
                    <div tabIndex="-1"></div>
                    <IonLabel>
                      <h2 className="bold">{__('WatchHouse café experience')}</h2>
                      <SmallText color="primary">{__('Comments related to your visit.')}</SmallText>
                    </IonLabel>
                    <IonCheckbox
                      slot="start"
                      color="secondary"
                      onIonChange={(e) =>
                        this.setCheckboxValue('customerService', e.detail.checked)
                      }
                      checked={customerService}
                    />
                  </IonItem>
                  <IonItem lines="none">
                    <div tabIndex="-1"></div>
                    <IonLabel>
                      <h2 className="bold">{__('WatchHouse app experience')}</h2>
                      <SmallText color="primary">
                        {__('Comments related to using our app.')}
                      </SmallText>
                    </IonLabel>
                    <IonCheckbox
                      slot="start"
                      color="secondary"
                      onIonChange={(e) => this.setCheckboxValue('techSupport', e.detail.checked)}
                      checked={techSupport}
                    />
                  </IonItem>
                </div>
              </IonList>
            </div>
            {techSupport ? (
              <div className="top-medium pad10 tech-box-holder">
                <div className="feedback-label bold">{__('Comments')}:</div>
                <div>
                  <IonTextarea
                    className="thiner-text"
                    value={commentTech}
                    onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                  ></IonTextarea>
                </div>
                <div className="separator"></div>
                <br />
              </div>
            ) : (
              <>
                <div className="box-holder box-holder--feedback top-medium">
                  <div className="inline-input">
                    <div className="feedback-label bold">{__('Rate our food and drink')}</div>
                    <div
                      style={{ marginBottom: 10 }}
                      className="flex-col-holder righted stars-wrapper"
                    >
                      {this.createStarSet(5, 'food')}
                    </div>
                  </div>
                  {this.state.formErrors.food ? (
                    <FieldError
                      className="field-error pad5l"
                      value={__(this.state.formErrors.food)}
                    />
                  ) : null}
                  <div className="inline-input inline-input--feedback">
                    <div className="feedback-label bold">{__('Rate our service')}</div>
                    <div className="flex-col-holder righted stars-wrapper">
                      {this.createStarSet(5, 'service')}
                    </div>
                  </div>
                  {this.state.formErrors.service ? (
                    <FieldError
                      className="field-error pad5l"
                      value={__(this.state.formErrors.service)}
                    />
                  ) : null}
                </div>
                <Spacer size={1} />
                <div className="feedback-label bold">{__('Additional Comments')}:</div>
                <div className="commentService">
                  <IonTextarea
                    className="thiner-text"
                    value={commentService}
                    onIonChange={(event) => this.setValue('commentService', event.detail.value)}
                  ></IonTextarea>
                </div>
                <div className="separator"></div>
                <br />
              </>
            )}
          </div>
          <br />
          <div className="action-wrapper">
            <IonButton
              className="round-button"
              expand="block"
              color="secondary"
              onClick={this.handleFeedback}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={isFeedbackModalOpen}
          onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
          header={__('Thank you')}
          message={__('We really appreciate your feedback and will pass it to the team.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
                this.setState({
                  food: 0,
                  service: 0,
                  customerService: true,
                  techSupport: false,
                  commentService: '',
                  commentTech: '',
                });
              },
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
