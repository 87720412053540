import React from 'react';
import { forwardTo } from '../../lib/utils';
import infoIcon from '../../assets/images/icons/info-icon.svg';
import './index.css';
import { SmallText, Title } from '../common';

const PromoBar = ({ __, type = ' ', onDismiss, onClick=null, vouchers, text, title, className , footerText, footerImage}) => {
  const formatCapitalizePromoText = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
    let str = text.charAt(0)?.toUpperCase() + text.slice(1).toLocaleLowerCase();
    return str;
  };
  if(vouchers && vouchers.length > 0 && vouchers[0].reward){
    return (
      <>
        <div className="promo-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
        <div
          className={'promo-bar ' + (type || 'info')}
          onClick={() => forwardTo('/vouchers', { openVoucherModal: vouchers[0].id })}
        >
          <div>
            <img src={infoIcon} />
          </div>
          <span className="promo-bar-type">{type || 'info'}:&nbsp;</span>
          <div className="promo-bar-body ellipsis">
            {formatCapitalizePromoText(vouchers[0].reward.small_print)}
          </div>
        </div>
      </>
    )
  }else if(text){
    return (
      <>
        <div className={`promo-bar-scrim`} onClick={onDismiss ? onDismiss : null}></div>
        <div
          className={`${className} promo-bar ${(type || 'info')}`}
          onClick={onClick ? onClick : null}
        >
          <div className="">
            <Title>
              {__(title)}
            </Title>
            <SmallText className='pre-line'>
              {__(text)}
            </SmallText>
            <div className='promo-bar-footer'>
              {footerText}
              <img src={footerImage} />
            </div>
            
          </div>
        </div>
      </>
    )
  }
   
};

export default PromoBar;
