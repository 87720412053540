import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonItem, IonInput } from '@ionic/react';
import { SmallText, Title, Spacer, NormalText } from '../common';
import { makeKey, isDefined, isWebConfig, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { removeVoucher, setModal } from '../../store/actions';
import Modal from '../../components/modal';

import './index.css';
import { getConfig } from '../../appConfig';

class OrderContent extends Component {
  constructor(props) {
		super(props);
		this.state = {
			serviceChargeModalOpen: false,
			showCharge: false,
			serviceChargeAmount: null,
			serviceChargeBoxModalOpen: false,
		};
	}

  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices, quantity } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow key={makeKey(choiceGroupIndex, choiceIndex, sku)}>
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow small-text">
                {quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR small-text">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  drawServiceChargeBox = (__, service_charge, basketInstance) => {
		return (
			<div className="service-charge-box-wrapper centered">
				<div>
					{!getConfig().serviceChargeBoxHidden && <NormalText>{basketInstance.getOrderType() === 'Click & Collect' || basketInstance.getOrderType() === 'collection' ? __('Add Tip?') : __('Add Service Charge?')}</NormalText>}
					<NormalText>{basketInstance.getServiceChargeValue() > 0 ? basketInstance.getFormatedServiceChargeValue() : basketInstance._getServiceCharge(true)}</NormalText>
				</div>
				<div className="service-charge-values">
					{service_charge.map((item) => {
						return (
							<IonButton
								className={`service-charge-button ${this.selectServicePercentage(item)? 'is-active' : ''}`}
								key={item + 'service_charge'}
								expand="block"
                fill="solid"
								onClick={(e) => {
									e.stopPropagation();
									document.querySelectorAll('.service-charge-values ion-button').forEach((el) => el.classList.remove('is-active'));
									e.target.classList.add('is-active');
									basketInstance.setServicePercentage(item);
								}}
							>
								{item === 0 ? <strong>{__('No thanks')} </strong> : <strong>{item}% </strong>}
							</IonButton>
						);
					})}
				</div>
        <div className='service-charge-diff-buttons'>
          <IonButton onClick={() => this.setState({ serviceChargeModalOpen: true, serviceChargeBoxModalOpen: false })} fill="clear" className="link underlined">
            {__('Add different amount')}
          </IonButton>
          <IonButton
            color="primary "
            onClick={() => {
              basketInstance.setServicePercentage(0);
              this.setState({ serviceChargeBoxModalOpen: false });
            }}
            fill="clear"
            className="link underlined block decline-service-charge-btn"
          >
            {__('Not this time')}
          </IonButton>
        </div>
			</div>
		);
	};

  setCustomServiceCharge = (value) => {
		if (value >= 0) {
			this.setState({ serviceChargeAmount: value, customAmountErrorVisible: false });
		} else {
			this.setState({ customAmountErrorVisible: true, serviceChargeAmount: 0 });
		}
	};

  selectServicePercentage(serviceCharge) {
		const basketInstance = this.props.basketInstance || Basket;
		const serviceChargeApplied = basketInstance.getServicePercentage();
		return serviceCharge === serviceChargeApplied;
	}
  
  handleServiceChargeModal = (flag) => {
		this.setState({ serviceChargeModalOpen: flag });
	};

  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      isRemoveVoucherModalOpen,
      dispatch,
    } = this.props;
    const { serviceChargeModalOpen } = this.state;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getAppliedVocuher();
    const total = basketInstance._getTotal(true);
    const orderType = basketInstance.getOrderType();
    let location = history && history.location && history.location.pathname === '/history-details' ? true : false;
    let service_charge_original = [];
    let service_charge = [];
    if (!basketInstance.service_charge_value && basketInstance.service_charge_value !== 0) {
      if (basketInstance.getRestaurantServiceCharge()) {
        service_charge_original = basketInstance.getRestaurantServiceCharge();
      } else {
        service_charge_original = getConfig().general?.defaultServiceCharge || [];
      }
      service_charge = service_charge_original.map((el) => parseFloat(el * 100));
    }
    const orderIsCollection = basketInstance.getOrderType() === 'Click & Collect' || basketInstance.getOrderType() === 'collection';
    return (
      <IonGrid className="order-content-wrapper paddLR">
        {getConfig().general.hasServiceCharge && !basketInstance.service_charge_value && basketInstance.service_charge_value !== 0 && (
					<>
						{!getConfig().general.serviceChargeBoxHidden ? (
							<>{this.drawServiceChargeBox(__, service_charge, basketInstance)}</>
						) : (
							<>
								<Modal className="service-charge-box-modal" isOpen={this.state.serviceChargeBoxModalOpen} onDidDismiss={() => this.setState({ serviceChargeBoxModalOpen: false })}>
									<Title>{orderIsCollection ? __('Add Tip?') : __('Add Service Charge?')}</Title>
									<Spacer size={1} />
									{this.drawServiceChargeBox(__, service_charge, basketInstance)}
									<Spacer size={1} />
									<IonButton
										expand="block"
										color="primary"
										onClick={() => {
											this.setState({ serviceChargeBoxModalOpen: false });
										}}
									>
										{__('Add Service Charge')}
									</IonButton>
								</Modal>
							</>
						)}
						<Spacer size={1} />
					</>
				)}
        <NormalText className="order-header">{__('Your Order')}</NormalText>
        <Spacer size={2} />
        {basketInstance.getItems().map((basketItem, basketItemIndex) => {
          const { quantity, item, instructions } = basketItem;
          return (
            <div key={basketItemIndex} className="basket-item-wrapper">
              <IonRow
                key={makeKey(basketItemIndex, item.sku)}
                onClick={() => {
                  if (handleOrderItemClick) {
                    handleOrderItemClick(basketItem, basketItemIndex);
                  }
                }}
              >
                <IonCol className="thiner-text">
                  {quantity} x {Basket.getProductName(item, profile)}
                </IonCol>
                <IonCol className="righted paddLR self-center small-text">
                  {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                    ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                    : null}
                </IonCol>
              </IonRow>
              {this.drawSubItems(basketItem, basketItemIndex)}
              {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
          );
        })}
        {showAddItems ? (
          <IonRow className="righted add-items-row">
            <IonButton
              size="small"
              color="black"
              className="add-items-btn"
              onClick={() => forwardTo('/order')}
            >
              + {__('Add Items')}
            </IonButton>
          </IonRow>
        ) : null}
        {appliedVoucher &&
          appliedVoucher.length > 0 &&
          appliedVoucher.map((voucher) => {
            return (
              <div key={voucher.id}>
                <Spacer size={1} />
                <Title>{__('Vouchers')}</Title>
                <IonRow
                  onClick={() =>
                    location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                  }
                >
                  <IonCol className="paddLR grow">{__(voucher.reward.name)} </IonCol>
                  <IonCol className="righted paddLR">
                    {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                  </IonCol>
                </IonRow>
                <Modal
                  className="remove-apply-voucuher"
                  title={__('Do you want to remove voucher') + ' ?'}
                  actionLabel={__('Yes')}
                  action={() => dispatch(removeVoucher(voucher.id))}
                  isOpen={!!isRemoveVoucherModalOpen}
                  onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                  noContent={true}
                />
              </div>
            );
          })}
        <>
          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow
              onClick={() => {
                if (type === 'order' && history) {
                  if (isWebConfig()) {
                    this.props.handleApplyModal(true);
                  } else {
                    history.push('/apply-vouchers');
                  }
                }
              }}
            >
              <IonCol className="paddLR grow">
                {appliedPoints}&nbsp;{__('Points Redeem')}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance._calculatePointsAppliedPrice(null, true)}
              </IonCol>
            </IonRow>
          ) : null}
          {deliveryPriceUnformated > 0 ? (
            <IonRow className="total-bordered-bottom">
              <IonCol className="paddLR grow">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {basketInstance.getServiceCharge() > 0 ? (
            <div key="service_charge" className="basket-item-wrapper">
              <IonRow
                className="basket-item-service-charge-row"
                onClick={() => {
                  if (handleOrderItemClick) {
                    basketInstance.setServicePercentage(0);
                  }
                }}
              >
                <IonCol className="paddLR grow small-text">
                  {orderIsCollection ? __('Tip') :__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                </IonCol>
                <IonCol className="righted paddLR self-center small-text">
                  {basketInstance._getServiceCharge(true)}
                </IonCol>
              </IonRow>
            </div>
          ) : null}
        </>
        <IonRow
          className={
            deliveryPriceUnformated > 0
              ? 'total-bordered-bottom'
              : 'total-bordered-top total-bordered-bottom'
          }
        >
          <IonCol className="paddLR">
            <SmallText color="primary" className="uppercase paddLR order-heading">
              {__('Total')}
            </SmallText>
          </IonCol>
          <IonCol className="righted paddLR order-heading">{total}</IonCol>
        </IonRow>
        <Modal className="service-charge-modal" isOpen={serviceChargeModalOpen} onDidDismiss={() => this.handleServiceChargeModal(false)}>
          <Title>{orderIsCollection ? __('Add Tip') : __('Add Service Charge')}</Title>
          <Spacer size={1} />
          <IonItem  className="input-field-wrapper">
            <IonInput className="okx-font-secondary" onIonChange={(e) => this.setCustomServiceCharge(e.target.value)} type="number" pattern="number" inputmode="number"></IonInput>
          </IonItem>
          <Spacer size={1} />
          {this.state.customAmountErrorVisible && (
            <>
              <SmallText className="danger-color">{__('Value can not be negative')}</SmallText>
              <Spacer size={1} />
            </>
          )}
          <IonButton
            expand="block"
            onClick={() => {
              basketInstance.setServiceCharge(this.state.serviceChargeAmount);
              this.setState({ serviceChargeModalOpen: false });
            }}
            disabled={!this.state.serviceChargeAmount}
          >
            {this.state.serviceChargeAmount ? (
              <>
                {__('Add')} {basketInstance.formatPrice(this.state.serviceChargeAmount)}
              </>
            ) : (
              <>{__('Add')}</>
            )}
          </IonButton>
        </Modal>
      </IonGrid>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
