import {
  SET_RESTAURANT_PROP,
  BEFORE_SHOW_TIME_PICKER,
  BEFORE_CLOSE_TIME_PICKER,
  CLEAR_IKENOO_MENU,
  PASSED_ORDER,
} from './constants';

let initialState = {
  restaurants: [],
  rewards: [],
  ikentooMenu: {},
  ikentooMenusForLocation: [],
  isShowTimePicker: false,
  isPassedOrder: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESTAURANT_PROP:
      return { ...state, [action.key]: action.value };
    case BEFORE_SHOW_TIME_PICKER:
      return { ...state, isShowTimePicker: true };
    case BEFORE_CLOSE_TIME_PICKER:
      return { ...state, isShowTimePicker: false };
    case CLEAR_IKENOO_MENU:
      return { ...state, ikentooMenu: {} };
    case PASSED_ORDER:
      return { ...state, isPassedOrder: action.isPassedOrder };
    default:
      return state;
  }
}

export default reducer;
