import React from 'react';
import { Title, SmallText } from '../common';
import { isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';

const { getRestauranName, getOrderDate, getOrderTime, getTableNumber } = Basket;

const formatDayName = (name) => {
  if (name.includes('Today')) {
    name = 'Today'.toLowerCase();
  } else if (name.includes('Tomorrow')) {
    name = 'Tomorrow'.toLowerCase();
  } else {
    name = getOrderDate();
  }
  return name;
};

const findSelectedSlot = (dayInWeek, time) => {
  const restaurant = Basket.getRestaurant();
  const selectedDay = restaurant.delivery_times_json.slots[dayInWeek];
  const selectedSlot = selectedDay.find(
    (day) =>
      moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'),
  );
  return (
    moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
    ' - ' +
    moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  );
};

const ContentHeader = ({ __, deliveryOption, orderType, deliveryAddress, pickUpPoint }) => {
  let dateName = formatDayName(
    moment(getOrderDate(), 'dddd Do MMM').calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
    }),
  );
  let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd');
  const deliveryLabel =
    deliveryOption?.label === 'Order for Delivery' ? 'Delivery Order' : deliveryOption?.label;
  const locationCode = pickUpPoint && pickUpPoint.location_code ? pickUpPoint.location_code : '';
  if (!orderType || orderType === '') {
    return null;
  }
  if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <SmallText className="auto-grid">
          <span>
            {__('Ordering for')} {__('drop-off')} {__('at')}{' '}
            <strong>
              {getRestauranName()} {`(#${locationCode})`},{' '}
            </strong>
          </span>{' '}
          <span>
            {dateName} {__('at')} {getOrderTime()}
            <br />
          </span>
        </SmallText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryLabel)}</Title>
        <SmallText className="heading thiner-text auto-grid">
          <span>
            {__('Ordering for')} {__('delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
            {dateName}
          </span>{' '}
          {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */}{' '}
          <span>
            {__('at')} {findSelectedSlot(dayInWeek, getOrderTime())}
            <br />
          </span>
        </SmallText>
      </>
    );
  } else if (
    (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') ||
    orderType == 'Table'
  ) {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <SmallText className="flex-col-wrapper flex-row-holder order-small-text">
          {__('Ordering at')} {getRestauranName()} {__('for Table')} {getTableNumber() + '.'}{' '}
          {__('Your order will be brought to you as soon as possible.')}
        </SmallText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery') {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : ' as soon as possible.';
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <SmallText /*className="flex-col-wrapper flex-row-holder"*/ className="heading">
          <span>
            {__('Ordering at')} {getRestauranName()} {__('for delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
          </span>
          <span>
            {dateRes}
            <br />
          </span>
          {/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
          {/* { ' ' + __('from')} { getRestauranName()} */}
          {/* <span style={{ marginBottom: 5 }} className="flex-col-wrapper"><strong>{ __('Collection Location') }</strong> <span className="righted thiner-text">{ getRestauranName() }</span></span>
					<span className="flex-col-wrapper"><strong>{ __('Collection Time') }</strong> <span style={ styledText } className="righted thiner-text">{ charterDeliveryDateAndTime !== 'Invalid date' ? `${getOrderDate('D MMMM YYYY')} ${__('at')}  ${getOrderTime('h:mm a')}` : `${Basket.collection_time}`}</span></span> */}
        </SmallText>
      </>
    );
  } else {
    return (
      <>
        <Title className="order-intro-title">
          {orderType === 'Click & Collect.' ? 'Click and collect.' : __(orderType)}
        </Title>
        <SmallText className="flex-col-wrapper flex-row-holder order-small-text">
          <span style={{ marginBottom: 5 }} className="flex-col-wrapper">
            {__('Ordering for collection from')} {getRestauranName()} {__('at')}{' '}
            {getOrderTime('h:mm a')}
          </span>
        </SmallText>
      </>
    );
  }
};

export default ContentHeader;
