import React from 'react';
import { IonButton } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, Spacer, Sectiontitle } from '../../components/common';
import Basket from '../../lib/basket';
import { PillGroup } from '../../components/pill';
import { goBack, isDefined } from '../../lib/utils';

const { getItems, getAllergen } = Basket;

class AllergenInformation extends React.Component {
  render() {
    const { __ } = this.props;
    const allergens = getAllergen() || [];
    return (
      <Loading>
        <Layout
          headerTitle={__('Order Summary')}
          hideSecondToolbar={true}
          backHandler={() => goBack()}
          scrollY={false}
        >
          <div
            style={{ width: '100%', height: '100%' }}
            className="absolute-content flex-row-wrapper"
          >
            <div className="scrollable-y rhino">
              <Title>{__('Allergen Information.')}</Title>
              {getItems().map((item, index) => {
                let allAllergens = [];
                let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                if (data && data.length > 0) {
                  allAllergens.push(
                    <div key={'allergen-' + index}>
                      <Sectiontitle className="allergen-title">
                        {item.quantity} x {item.item.productName}
                      </Sectiontitle>
                      <PillGroup
                        items={data[0].allergens}
                        borderColor="secondary"
                        color="secondary"
                        headerTitle="Order Summary"
                        className="allergen-pill"
                        paddingTop={4}
                      />
                      <Spacer />
                    </div>,
                  );
                }
                // check allergens for sub-items
                if (isDefined(item.selectedChoices)) {
                  item.selectedChoices.forEach((group) => {
                    group.forEach((item) => {
                      if (
                        isDefined(item.itemRichData) &&
                        isDefined(item.itemRichData.allergenCodes) &&
                        item.itemRichData.allergenCodes.length > 0
                      ) {
                        let data1 = allergens.find((allergen) => allergen[1].sku === item.sku);
                        if (data1 && data1.length > 0) {
                          allAllergens.push(
                            <div key={'allergen_subitem_' + index + '_' + item.productName}>
                              <Sectiontitle>{item.productName}</Sectiontitle>
                              <PillGroup items={data1[0].allergens} borderColor="primary" />
                              <Spacer />
                            </div>,
                          );
                        }
                      }
                    });
                  });
                }
                return allAllergens;
              })}
            </div>
            <div className="flex-min">
              <Spacer />
              <IonButton
                expand="block"
                className="round-button"
                color="secondary"
                onClick={() => goBack()}
              >
                {__('Back')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(AllergenInformation);
