import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import Basket from '../../lib/basket';
import ValidateButton from '../../components/validateButton';
import { pencilOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import { forwardTo, validateProfileData, goBack, go, isWebConfig } from '../../lib/utils';
import { setModal, setProtectedReferrer, restoreAuth } from '../../store/actions';
import ContentHeader from '../../components/contentHeader';
import './index.css';

const {
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getPickUpPoint,
  getDeliveryAddress,
  getDeliveryOption,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = () => {
    const { cards, auth, history } = this.props;
    const isAuth = auth.loggedIn;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    const uuid = `${this.props.profile.id}_${Date.now()}`
    Basket.setUUID(uuid)
    if (getTotal() === 0) {
      Basket.createOrder();
      return;
    }
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.pathname));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout');
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-vouchers');
      }
    }
  };

  render() {
    const { __, profile, auth, dispatch } = this.props;
    const { quantityModal, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };
    const isAuth = auth.loggedIn;
    const labelTitle =
      Basket.getOrderType() === 'Click & Collect'
        ? 'Click and collect order.'
        : Basket.getOrderType();
    const appliedVouchers = Basket.getAppliedVocuher();
    return (
      <>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y checkout">
            {Basket.getItems().length > 0 ? (
              <ContentHeader
                __={__}
                deliveryOption={this.props.deliveryOption || getDeliveryOption()}
                orderType={labelTitle}
                deliveryAddress={getDeliveryAddress()}
                pickUpPoint={getPickUpPoint()}
              />
            ) : null}
            <IonItem className="contact-number" lines="full">
              <div tabIndex="-1"></div>
              <IonLabel {...contactLabelArrg} className="ion-text-wrap contact-number-label">
                <Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
              </IonLabel>
              <IonInput
                className="mobile-field"
                onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                clearInput
                required={true}
                type="tel"
                pattern="tel"
                inputmode="tel"
                value={mobile}
              >
                {mobile === '' ? (
                  // <IonButtons slot='end' className='no-margin'>
                  // 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
                  // 	</IonButton>
                  // </IonButtons>
                  <IonIcon icon={pencilOutline} className="contact-number-icon" />
                ) : null}
              </IonInput>
            </IonItem>
            <Spacer size={1} />
            {itemsCount() > 0 ? (
              <OrderContent
                showAddItems={true}
                handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                handleApplyModal={this.handleApplyModal}
              />
            ) : (
              <>
                <IonItem lines="none">
                  <div
                    tabIndex="-1"
                    className="sectiontitle"
                    style={{ width: '100vh', textAlign: 'center' }}
                  >
                    {__('No items')}
                  </div>
                </IonItem>
                <IonButton color="secondary" expand="block" className="checkout-btn round-button"onClick={() => forwardTo('/order')}>
                  {__('Add items')}
                </IonButton>
              </>
            )}
            {allergens.length > 0 ? (
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined allergen-link"
                color="warning"
                onClick={() => forwardTo('/allergen-information')}
              >
                {__('Allergen info')}
              </IonButton>
            ) : null}
            <div className="validate-content">
              <ValidateButton />
              {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                <div className="verified-content">
                  <SmallText color="grey">
                    {__('You can earn, but not redeem points until your account is verified')}
                  </SmallText>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex-min">
            {getConfig().appType.hasUnlockRewardsOnLoyalty ? null : (
              <IonButton
                className={`redeem-points-btn round-button ${
                  itemsCount() === 0 || !valid ? 'disabled' : ''
                }`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                expand="block"
                fill="outline"
                color="dark"
              >
                {appliedVouchers && appliedVouchers.length > 0
                  ? __('Replace rewards')
                  : __('Redeem rewards')}
              </IonButton>
            )}
            {/* <IonButton className="web-only" disabled={ itemsCount() === 0 || !valid } onClick={() => this.handleApplyModal(true)} expand="block" fill="outline" color="dark">
							<IonIcon slot="start" icon={ redeemIcon } />
							{ __('Redeem Points') }
						</IonButton> */}
            <IonButton
              disabled={itemsCount() === 0}
              onClick={this.handleSubmit}
              color="secondary"
              expand="block"
              className={
                'checkout-btn round-button ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')
              }
            >
              {__('Checkout')}
            </IonButton>
          </div>
        </div>

        <Modal
          cssClass="quantity-modal"
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>

        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          {/* <ApplyPoints handleApplyModal={ this.handleApplyModal } applyPointsModalOpen={ applyPointsModalOpen } /> */}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    deliveryOption,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
  };
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout
          headerTitle={__('Order Summary')}
          hideSecondToolbar={true}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
