import React from 'react';
import QRCodeCanvas from 'qrcode.react';
import ValidateButton from '../../components/validateButton';
import { withTranslation } from '../../lib/translate';
import { isDefined, validateProfileData } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { SmallText, Title } from '../../components/common';
import AddToWallet from '../../components/addToWallet';

const Scan = ({ __, qr_code, profile }) => {
  const valid = validateProfileData(profile).isValid;
  const { first_name, last_name } = profile;
  const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
  return (
    <>
      <div className="loyalty-content scan overflow-visible">
        <div className="loyalty-title-wrap">
          {(first_name || last_name) && (
            <Title className="scan-title">{__(`${first_name} ${last_name}.`)}</Title>
          )}
          <SmallText color="primary" className="heading scan-heading">
            {__(
              'Show your QR code to our team when ordering to earn WatchHouse stamps and rewards.',
            )}
          </SmallText>
        </div>
        {isDefined(qr_code) ? (
          <div className="qr-holder centered">
            <QRCodeCanvas value={qr_code} size={200} />
          </div>
        ) : (
          <div className="noQrCode">
            <h5>{__('NO QR CODE')}</h5>
          </div>
        )}
        <div>
        {isDefined(profile.qr_code) && profile.qr_code !== '' && (hasAddToAppleWallet || hasAddToGoogleWallet) ? <AddToWallet /> : null}
        <ValidateButton />
        {!valid && getConfig().appType.hasEmailValidationEnabled ? (
          <div className="verified-content">
            <SmallText color="grey">
              {__('You can earn, but not redeem points until your account is verified')}
            </SmallText>
          </div>
        ) : null}
        </div>
        
      </div>
    </>
  );
};

export default withTranslation(Scan);
