import { getEnv } from './lib/env.js';

let appConfig = {
  theme: {
    nav: {
      label: 'WatchHouse',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
  },
  api: {
    baseURL: getEnv('BASE_URL'),
    wordPressBaseURL: 'https://hybridapp.co.uk',
    nativeWallet: getEnv('NATIVE_WALLET_API'),
  },
  general: {
    clientName: 'WatchHouse',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    routesWithBackButton: [
      '/cards',
      '/card-add',
      '/allergen-information',
      '/order-summary',
      '/vouchers',
      '/order-completed',
      '/apply-vouchers',
      '/terms',
      '/privacy',
      '/faq',
      '/contact-details',
      '/click-and-collect',
      '/order-to-table',
      '/history-details',
    ],
    isReduxDevToolsOn: true,
    isWebPlatform: false,
  },
  appType: {
    hasOrdering: true
  },
  configS3: {
    imageNamePrefix: 'hyfive_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1'
  },
  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
    measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
  },
  payment: 'stripe',
  vouchersType: 1,
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
