import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoLinkedin, globe } from 'ionicons/icons';
import { getSocials } from '../../store/actions';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, SmallText, Spacer } from '../../components/common';
import { ucWords, isDefined, isEmptyObject } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import './index.css';
import NoData from '../../components/noData';
import logoTwitter from '../../assets/images/icons/twitter.svg';
import logoInstagram from '../../assets/images/icons/instagram.svg';
import logoFacebook from '../../assets/images/icons/facebook.svg';
import homeSubscriptionLogo from '../../assets/images/icons/home-subscription.png';
import shopCoffeesLogo from '../../assets/images/icons/shop-coffees.png';
import brewingKitsLogo from '../../assets/images/icons/brewing-kits.png';
import { getConfig } from '../../appConfig';

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');

class Social extends Component {
  componentDidMount() {
    this.props.dispatch(getSocials());
  }

  parseSocialItem(key = '', value = '') {
    let label = key;
    let icon = key;
    let link = value;
    let uppercasedKey = key
      .split('')
      .map((char, i) => (i === 0 ? char.toUpperCase() : char))
      .join('');
    switch (uppercasedKey) {
      case 'Facebook':
        icon = logoFacebook;
        break;
      case 'Twitter':
        icon = logoTwitter;
        break;
      case 'Instagram':
        icon = logoInstagram;
        break;
      case 'Linkedin':
        icon = logoLinkedin;
        break;
      default:
        icon = globe;
        break;
    }
    return {
      link,
      icon,
      label,
    };
  }


  render() {
    const { __, social } = this.props;
    return (
      <Loading>
        <Layout color="white" headerTitle={__('Social Media')} hideSecondToolbar={true}>
          <div className="header-wrapper">
            <Title>{__('Connect with us.')}</Title>
            <SmallText color="primary" className="heading thiner-text">
              {__('Get the latest info and offers by following us on our social media channels.')}
            </SmallText>
          </div>
          <Spacer size={2} />
          <div className="">
            {isDefined(social) && !isEmptyObject(social) ? (
              <div className="social-wrapper">
                {Object.keys(social || {}).map((key, index) => {
                  if (key.toLowerCase() !== 'website') {
                    const value = social[key];
                    const { link, icon, label } = this.parseSocialItem(key, value);
                    const capitalizeLabel = ucWords(label);
                    return (
                      <div className="social-item-wrapper" onClick={() => openExternalLink(link)}>
                        <div className="social-item">
                          <div className="social-icon">
                            <img src={icon} />
                          </div>
                          <SmallText color="primary" className="social-text">
                            {__(capitalizeLabel)}
                          </SmallText>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <NoData label={__('No social networks')} />
            )}
          </div>
          <Spacer size={2} />
          <div className="header-wrapper">
            <Title>{__('Shop WatchHouse.')}</Title>
          </div>
          <div className="">
            <div className="social-wrapper">
              {getConfig().general.shopWatchHouseLinks.map((link) => {
                return (
                  <div className="social-item-wrapper" onClick={() => openExternalLink(link.link)}>
                    <div className="social-item">
                      <div className="social-icon shop-icon">
                        <img src={`data:image/svg+xml;base64,${link.icon}`} />
                      </div>
                      <SmallText color="primary" className="social-text">
                        {__(link.label)}
                      </SmallText>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { social } = state.common;
  return {
    social,
  };
};

export default connect(stateToProps)(withTranslation(Social));
