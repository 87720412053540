import React from 'react';
import { IonButton, IonItem, IonInput, IonLabel } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCodeCanvas from 'qrcode.react';
import moment from '../../lib/moment';
import noVoucherImage from '../../assets/images/no-voucher.png';
import voucherImage from '../../assets/images/voucher.svg';
import './index.css';
import { GET_VOUCHERS } from '../../store/constants';
import { getConfig } from '../../appConfig';

// temporary dummy array
const dummyVouchers = [
  {
    id: 'voucher-1',
    name: '£5 off any meal',
    subtitle: 'off any meal',
    description: 'You received this voucher for £5 off any meal.',
    stamp: 'food-1',
    className: 'green',
    heading: 'Coffee Stamp Reward',
    subheading: 'Loyalty Card Free Coffee Reward',
    expiration: '03/09/20',
    image: voucherImage,
    details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
    qr: '??/assets/images/qr-dummy.png',
  },
  {
    id: 'voucher-2',
    name: 'Double stamps',
    titleSecondary: 'stamps',
    description: 'Monday Lunch between 11.30 - 14.30',
    stamp: 'food-1',
    className: 'black default-font',
    heading: 'Double Stamp Reward',
    subheading: 'Loyalty Card Reward',
    image: voucherImage,
    expiration: '03/05/20',
    details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
    qr: '??/assets/images/qr-dummy.png',
  },
];

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      className={'voucher' + (className ? ' ' + className : '')}
      onClick={action ? () => action(id) : null}
    >
      <img src={reward.image} />
    </div>
  );
};

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      delay: getConfig().general.refreshPeriod * 1000
    };
    this.interval = null
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: GET_VOUCHERS });
    this.interval = setInterval(this.fetchVouchers, this.state.delay);
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }
  componentDidUpdate(prevProps,prevState){
    if (prevState.delay !== this.state.delay) {
      clearInterval(this.interval);
      this.interval = setInterval(this.fetchVouchers, this.state.delay);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  fetchVouchers = () => {
    this.props.dispatch({ type: GET_VOUCHERS });

  }
  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  render() {
    const { __, vouchers, qr_code } = this.props;
    const { voucherCode, selectedVoucher } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const noVoucher = {
      name: 'No current rewards.',
      description: 'Earn rewards by buying coffee or by adding voucher codes.',
      image: noVoucherImage,
    };
    const clickupContent = (
      <Clickup label={__('Redeem Voucher Code')} height="320">
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom scrollable-y">
            <p className="no-margin-top">{__('Enter your voucher code below')}</p>
            <div className="lefted no-padding-bottom voucher-item">
              <IonItem style={{ '--min-height': '32px' }}>
                <div className="inline-input">
                  <IonInput
                    value={voucherCode}
                    onIonChange={(e) => this.handlerOfVoucherCode(e)}
                    placeholder={__('Voucher code')}
                  />
                </div>
                <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              </IonItem>
            </div>
          </div>
          <div className="padded no-padding-top flex-min">
            <IonButton
              className="clickable round-button white-button"
              disabled={voucherCode === ''}
              expand="block"
              onClick={this.sendCode}
            >
              <IonLabel>{__('Redeem')}</IonLabel>
            </IonButton>
          </div>
        </div>
      </Clickup>
    );
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          hideSecondToolbar={true}
          headerTitle={__('Vouchers')}
          scrollY={false}
        >
               
          <div className="absolute-content flex-row-wrapper vouchers-wrapper">
            <Title className="rewards-title">{__('My rewards.')}</Title>
            <div className="scrollable-y">
              {!vouchers || vouchers.length === 0 ? (
                <VoucherItem
                  reward={noVoucher}
                  key="no-voucher"
                  className="no-voucher"
                  __={__}
                  label="Your vouchers will appear here"
                />
              ) : null}
              {vouchers &&
                vouchers.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={() => this.viewVoucher(voucher.id)}
                    />
                  );
                })}
              <Spacer size={2} />
            </div>
          </div>
          <div className="flex-min">{clickupContent}</div>
        </Layout>
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => this.setState({ selectedVoucher: null })}
          title={__('Voucher Info')}
          color="black"
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div>
              <VoucherItem key={selectedVoucher.reward.id} {...selectedVoucher} __={__} />
              <div className="centered">
                <div className="voucher-modal-content">
                  {selectedVoucher.reward.small_print ? (
                    <div>
                      <NormalText className="voucher-text">
                        {selectedVoucher.reward.small_print}
                      </NormalText>
                    </div>
                  ) : null}

                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={qr_code} size={200} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                  {selectedVoucher.reward.expiry_date ? (
                    <div>
                      <NormalText className="voucher-small-text">
                        {__('Expires:')}{' '}
                        {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format(
                          'DD/MM/YY',
                        )}
                      </NormalText>
                    </div>
                  ) : null}
                  <Spacer size={1} />
                  <div onClick={() => forwardTo('/terms')}>
                    <NormalText className="voucher-text">{__('Terms & Conditions')}</NormalText>
                  </div>
                  <Spacer size={1} />
                  {selectedVoucher.reward.description ? (
                    <div>
                      <SmallText className="voucher-description">
                        {__(selectedVoucher.reward.description)}
                      </SmallText>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
