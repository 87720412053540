import axios from 'axios';
import { isDefined } from './utils';
import { getConfig } from '../appConfig';
import packageInfo from '../../package.json';

let axiosInstance;

const baseURL = getConfig().api.baseURL;
const wordPressBaseURL = getConfig().api.wordPressBaseURL;
let addToGoogleWallet = getConfig().api.nativeWallet;
addToGoogleWallet = `${addToGoogleWallet}/google-wallet`;
const endpoints = {
  login: '/api-token-auth', //post
  register: '/me/register', //post
  getProfile: '/me', //get
  changePassword: '/me', //put
  changeProfile: '/me', //put
  resetPassword: '/me/reset_password', //put
  deleteCard: '/delete_payment_card', //put
  sendFeedback: '/feedback/create', //post,
  firebaseToken: '/firebase-token', //post,
  appVersion: '/appversion', //get,
  social: '/social', //get,
  restaurants: '/restaurants/all', //get
  terms: '/terms', //get,
  privacyPolicy: '/privacy', //get
  faq: '/faq', //get
  rewards: '/reward_milestones', //get
  history: '/transactions/history', //get
  ikentooMenu: '/ikentoo_menu/', //get
  ikentooMenusForLocation: '/ikentoo_menus/', //get
  getPaymentCards: '/orders/list_payment_cards', //post
  addPaymentCard: '/orders/create_payment_card', //post
  removePaymentCard: '/orders/delete_payment_card', //post
  createOrder: '/order/create', //post
  orderHistory: '/me/orders', //get
  validateEmail: '/me/validate_email', //get
  sendVoucherCode: '/voucher/code', //put,
  referFriend: '/refer_friend', //post
  getVouchers: '/me/vouchers', //get
  publicStripeKey: '/public_stripe_key', //get
  frontEndAppConfig: '/front_end_app_config', //get
  getTranslations: '/translation_json', //get
  addDeliveryAdress: '/me/add_new_address', //put
  getRestaurantSnoozeData: '/ikentoo_menu/get_snooze_data', //get
  postCodeCheck: '/order/postcode_check', //post
  getNearestLocation: '/order/get_nearest_restaurant_location', //put
  getDeliveryRangeType: '/get_delivery_range_type', //get
  locationCodeCheck: '/order/location_code_check', // post
  addPickupPoint: '/me/add_pickup_point', // put,
  getOrderProduction: '/order_production_length',
  removeDeliveryAddress: '/orders/remove_delivery_address', //post
  cancelOrder: '/orders/cancel_order', // post
  getDefaultMenuId: '/get_default_menu_id', //get
  getDefaultMenu: '/get_default_menu', //get
  saveJudoPayCard: '/orders/save_judo_pay_cards', //post
  checkDiscount: '/order/check_discount', //post
  getLastOrder: '/orders/get_last_order', // get
  paymentInfo: '/update_payment_info',
  createStripeOrder: '/order/create_stripe_order', //post
  updateStripeOrder: '/order/update_stripe_order', //post
  removeProfile: '/me/delete_user', // post
  createStripeOrderNative: '/order/create_stripe_order_native',
  confirmPaymentIntentNative: '/order/confirm_payment_intent_native',
  updateProfileImage: '/me/update_profile_image'
};

const createAxiosInstance = (token) => {
  const headers = isDefined(token)
    ? {
        Authorization: 'JWT ' + token,
      }
    : {};
  axiosInstance = axios.create({
    headers,
    timeout: 50000,
  });
  return axiosInstance;
};

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName]);

export const getErrorMessage = (fieldName, errors = {}) =>
  !isFieldValid(fieldName, errors) ? errors[fieldName] : '';

const api = {
  login: async (username, password) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.login + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      username,
      password,
      version: packageInfo.version,
    });
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  register: async (userData) => {
    const response = await createAxiosInstance().post(baseURL + endpoints.register + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...userData, version: packageInfo.version});
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  logout: () => {
    // eslint-disable-next-line no-console
  },
  resetPassword: async (email) =>
    await axiosInstance.put(baseURL + endpoints.resetPassword + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { email, version: packageInfo.version }),
  getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`,),
  updateProfile: async (data = {}) =>
    await axiosInstance.put(baseURL + endpoints.changeProfile + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  sendFirebaseToken: async (data) =>
    await axiosInstance.post(baseURL + endpoints.firebaseToken + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getAppVersion: () =>
    axiosInstance.get(baseURL + endpoints.appVersion + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.data.app_version),
  getSocialLinks: () =>
    axiosInstance.get(baseURL + endpoints.social + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.social_link_json),
  sendFeedback: async (data = {}) =>
    await axiosInstance.post(baseURL + endpoints.sendFeedback + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getRestaurants: () =>
    axiosInstance.get(baseURL + endpoints.restaurants + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.data),
  getTerms: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/terms_conditions/' + getConfig().envs.APP_DOMAIN, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getPrivacyPolicy: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/privacy/' + getConfig().envs.APP_DOMAIN, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getFaq: (locale = 'en') =>
    axios
      .create()
      .get(wordPressBaseURL + '/' + locale + '/faqs/' + getConfig().envs.APP_DOMAIN, {
        responseType: 'text',
      })
      .then((res) => res.data),
  getRewards: () => axiosInstance.get(baseURL + endpoints.rewards + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.data),
  getIkenooMenu: (menuId, businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenu + menuId + '/location/' + businessLocationId + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data),
  getIkentooMenusForLocation: (businessLocationId) =>
    axiosInstance
      .get(baseURL + endpoints.ikentooMenusForLocation + 'location/' + businessLocationId + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data),
  getHistory: () => axiosInstance.get(baseURL + endpoints.history + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data),
  getPaymentCards: () =>
    axiosInstance.post(baseURL + endpoints.getPaymentCards + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`,{version: packageInfo.version}).then((res) => res.data.data),
  addPaymentCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.addPaymentCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...cardData, version: packageInfo.version}).then((res) => res.data.data),
  removePaymentCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.removePaymentCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...cardData, version: packageInfo.version}).then((res) => res.data),
  createOrder: (orderData) =>
    axiosInstance.post(baseURL + endpoints.createOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...orderData, version: packageInfo.version}).then((res) => res.data),
  getOrderHistory: () =>
    axiosInstance.get(baseURL + endpoints.orderHistory + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data),
  sendCode: async (data) =>
    await axiosInstance.put(baseURL + endpoints.sendVoucherCode + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}).then((res) => res.data),
  sendRefer: async (data) =>
    await axiosInstance.post(baseURL + endpoints.referFriend + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}).then((res) => res.data),
  getVouchers: async () =>
    await axiosInstance.get(baseURL + endpoints.getVouchers + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data),
  validateEmail: async () => await axiosInstance.get(baseURL + endpoints.validateEmail + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`),
  getPublicStripeKey: () =>
    axiosInstance
      .get(baseURL + endpoints.publicStripeKey + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data.public_stripe_key),
  getFrontEndAppConfig: () =>
    axiosInstance
      .get(baseURL + endpoints.frontEndAppConfig + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data.front_end_app_config),
  // getTranslations: () => axiosInstance.get(baseURL + endpoints.getTranslations).then(res => res.data.data.translation_json),
  getTranslations: () =>
    axiosInstance
      .get(wordPressBaseURL + '/en/dictionary' + '/' + getConfig().envs.APP_DOMAIN)
      .then((res) => res.data),
  addDeliveryAdress: (data) => axiosInstance.put(baseURL + endpoints.addDeliveryAdress + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getRestaurantSnoozeData: () =>
    axiosInstance.get(baseURL + endpoints.getRestaurantSnoozeData + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.data),
  postCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.postCodeCheck, {...data, version: packageInfo.version}),
  getNearestLocation: (data) => axiosInstance.put(baseURL + endpoints.getNearestLocation + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getDeliveryRangeType: () =>
    axiosInstance
      .get(baseURL + endpoints.getDeliveryRangeType + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data.delivery_range_type),
  locationCodeCheck: (data) => axiosInstance.post(baseURL + endpoints.locationCodeCheck, {...data, version: packageInfo.version}),
  addPickupPoint: (data) => axiosInstance.put(baseURL + endpoints.addPickupPoint + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getOrderProduction: () =>
    axiosInstance
      .get(baseURL + endpoints.getOrderProduction + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data.order_production_mins),
  removeDeliveryAddress: (postalCode) =>
    axiosInstance
      .post(baseURL + endpoints.removeDeliveryAddress + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...postalCode, version: packageInfo.version})
      .then((res) => res.data),
  cancelOrder: (data) =>
    axiosInstance.post(baseURL + endpoints.cancelOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}).then((res) => res.data.data),
  getDefaultMenuId: () =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenuId + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data.default_menu_id),
  getDefaultMenu: (menuId) =>
    axiosInstance
      .get(baseURL + endpoints.getDefaultMenu + '/' + menuId + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`)
      .then((res) => res.data.data),
  saveJudoPayCard: (cardData) =>
    axiosInstance.post(baseURL + endpoints.saveJudoPayCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...cardData, version: packageInfo.version}).then((res) => res.data.data),
  checkDiscount: async (data) => await axiosInstance.post(baseURL + endpoints.checkDiscount + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  getLastOrder: (id) =>
    axiosInstance.get(baseURL + endpoints.getLastOrder + '/' + id + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true` : ''}`).then((res) => res.data.data),
  updatePaymentInfo: async (paymentInfo = {}) =>
    await axiosInstance
      .post(baseURL + endpoints.paymentInfo + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...paymentInfo, version: packageInfo.version})
      .then((res) => res.data.data),
  addToGoogleWallet: async (data) => await axiosInstance.post(addToGoogleWallet + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, appversion: packageInfo.version}).then((res) => res.data),
  createStripeOrder: async (orderData) => axiosInstance.post(baseURL + endpoints.createStripeOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...orderData, version: packageInfo.version}).then((res) => res.data),
  updateStripeOrder: async (orderId) => axiosInstance.post(baseURL + endpoints.updateStripeOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { orderId, version: packageInfo.version}).then((res) => res.data),
  removeProfile: async (data) => await axiosInstance.post(baseURL + endpoints.removeProfile + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version}),
  createStripeOrderNative: async (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createStripeOrderNative + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...orderData, version: packageInfo.version})
      .then((res) => res.data),
  confirmPaymentIntentNative: async (data) =>
    axiosInstance
      .post(baseURL + endpoints.confirmPaymentIntentNative + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {...data, version: packageInfo.version})
      .then((res) => res.data),
  updateProfileImage: async (data) => await axiosInstance.post(baseURL + endpoints.updateProfileImage, data ),

};

api.createAxiosInstance = createAxiosInstance;

export default { ...api };
