import { Capacitor } from '@capacitor/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonCheckbox,
  IonAlert,
  IonAvatar,
  IonIcon,
} from '@ionic/react';
import MaskedInput from 'react-text-mask';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { FieldError, Title, SmallText, Spacer, NormalText } from '../../components/common';
import { validateForm, isDefined, forwardTo, goBack } from '../../lib/utils';
// import moment from '../../lib/moment'
import { withTranslation } from '../../lib/translate';
import { updateProfile, setModal, loading, deleteUser, updateProfileImage } from '../../store/actions';
import { beforeCloseTimePicker } from '../../store/actions';
import ValidateButton from '../../components/validateButton';
import Loading from '../../components/spinner';
import defaultImg from '../../assets/images/gray-avatar.svg';
import Mobiscroll from '../../components/mobiscroll';
import packageJson from '../../../package.json';
import fiveLoyaltyLogo from '../../assets/images/logo/5loyalty-logo.svg';
import cameraIcon from '../../assets/images/icons/camera.svg';
import { Camera, CameraResultType } from '@capacitor/camera';
import './index.css';

const nameInputMask = Array(50).fill(/[a-z0-9A-Z ]/);

const { configS3 } = getConfig();
const { SelectOption } = Mobiscroll;
const isWeb = () => Capacitor.getPlatform() === 'web';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      email: this.props.profile.email || '',
      mobile: this.props.profile.mobile || '',
      birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      imageFile: null,
      is_subscribed: this.props.profile.is_subscribed || false,
      formErrors: {},
      deleteAvatarImageAlert: false,
      locale: this.props.profile.locale || getConfig().localization?.defaultLocale,
      closeMyAccount: false
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      email: { type: 'email', required: false },
      mobile: { type: 'tel', required: false },
      locale: { type: 'text', required: false },
    };
  }

  async componentDidMount() {
    this.checkVerison();
    if(this.props.location?.state?.openVerificationPopup){
      this.props.dispatch(setModal('isVerfiedModalOpen', true))
    }
  }

  checkVerison = () => {
    // web version checking
    if (!this.isAppVersionValid(this.props.appVersion, packageJson.version)) {
      this.handleModal(true);
    }
  };

  isAppVersionValid = (apiVersion = '', appVersion = '') => {
    let ret = true;
    if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
      const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10);
      const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10);
      ret = appVersionInt >= apiVersionInt;
    } else {
      // eslint-disable-next-line no-console
      console.error('Skip version checking.');
    }
    return ret;
  };

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.email !== prevProps.profile.email) {
      this.setState({ email: this.props.profile.email });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
    if (this.props.profile.birthday !== prevProps.profile.birthday) {
      this.setState({
        birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
      });
    }
    if (this.props.profile.locale !== prevProps.profile.locale) {
      this.setState({
        locale: this.props.profile.locale || getConfig().localization?.defaultLocale,
      });
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({
          profile_image_url: this.props.profile.profile_image_url,
        });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
    if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
      this.setState({ is_subscribed: this.props.profile.is_subscribed });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    this.props.dispatch(beforeCloseTimePicker());
  };

  handleLanguageInput = (event, data) => {
    this.setState({ locale: data.getVal() });
  };

  async takePicture() {
    let image;
    if (!isWeb()) {
      try {
        image = await Camera.getPhoto({
          quality: 50,
          allowEditing: true,
          resultType: CameraResultType.Uri
        });
        this.setState({
          profile_image_url: image.webPath,
        });
        this.updateProfileImage(image.webPath, image.format);
      } catch (error) {
        console.error('Error capturing photo:', error);
      }
    } else {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.capture = 'camera';
      try {
        input.onchange = (event) => {
          const selectedFile = event.target.files?.[0];
          if (selectedFile) {
            const imageFile = event.target.files[0];
            let reader = new window.FileReader();
            reader.readAsDataURL(imageFile);
            let extension = imageFile.name.split('.').pop();
            reader.onloadend = () => {
              this.setState({
                profile_image_url: reader.result,
              });
              this.updateProfileImage(reader.result, extension);
            };
          } else {
            console.log('Image is not selected!')
          }
        };
        input.click();
      } catch (error) {
        console.error('Error selecting image:', error);
      }
    }
  };

  getMimeType = (extension) => {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      default:
        return undefined;
    }
  };

  handleSave() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        first_name,
        last_name,
        email,
        mobile,
        birthday,
        is_subscribed,
        locale,
      } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        birthday: birthday,
        is_subscribed,
        locale,
      };
      this.props.dispatch(updateProfile(profile));
    }
  }

  updateProfileImage(profile_image_url, extension) {
    if(profile_image_url, extension){
      const imageName = `${configS3.imageNamePrefix}${Date.now()}${this.props.profile.id}`;
      fetch(profile_image_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new window.File([blob], imageName, {
            type: this.getMimeType(extension),
          });
          this.props.dispatch(updateProfileImage(file));
          this.setState({ imageFile: null });
        });
    } 
  }

  removeProfileImage = () => {
    const { profile } = this.props;
    const profile_image_url = profile.profile_image_url;
    this.handleAvatarDeleteAlert(false);
    if (profile_image_url) {
      let imageName = profile_image_url.split('/');
      if (imageName.length > 0) {
        imageName = imageName[imageName.length - 1];
        this.props.dispatch(updateProfile({ profile_image_url: null }));
        this.setState({ imageFile: null });
      }
    }
  };

  handleAvatarDeleteAlert = (flag = true) => this.setState({ deleteAvatarImageAlert: flag });

  handlePeriodicalSaga = (flag) => {
    const { dispatch } = this.props;
    // dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag })
  };

  formatDataForSelect = (langs) => {
    let arrForSelect = [];
    Object.keys(langs).forEach((key) => {
      arrForSelect.push({ text: langs[key], value: key });
    });
    return [{ text: '', value: null }, ...arrForSelect];
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.addCardFromAccount
    ) {
      forwardTo('/dashboard');
    } else {
      goBack();
    }
  };

  render() {
    const { __, isProfileModalOpen, history, profile, isShowTimePicker } = this.props;
    const {
      first_name,
      last_name,
      email,
      mobile,
      birthday,
      formErrors,
      profile_image_url,
      is_subscribed,
      deleteAvatarImageAlert,
      locale,
      closeMyAccount
    } = this.state;
    // const dateFormat = moment()._locale._longDateFormat.L
    const languages =
      getConfig().localization && getConfig().localization.supportedLocales
        ? Object.keys(getConfig().localization.supportedLocales)
        : null;
    const newClass = isShowTimePicker ? 'red' : 'gray';
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const avatar = profile?.profile_image_url ? profile.profile_image_url : profile_image_url;
    return (
      <Loading transparent>
        <Layout color="white" headerTitle="My Account" hideBack={true} hideSecondToolbar={true}>
          <div className="frm-wrapper">
            <div className="avatar-image-wrapper">
              <div className="avatar-circle">
                <IonAvatar className="profile-image-content">
                  {avatar ? (
                    <img
                      alt=""
                      src={avatar + (avatar.indexOf('http') !== -1 ? '?' + Date.now() : '')}
                      onClick={() => this.handleAvatarDeleteAlert(true)}
                    />
                  ) : null}
                  <div className="avatar-photo" onClick={async () => await this.takePicture()}>
                    <IonIcon icon={cameraIcon} size="small" color="dark" />
                  </div>
                </IonAvatar>
              </div>
            </div>
            <ValidateButton />
            <Spacer size={2} />
            <Title className="account-title">{__('My account.')}</Title>
            <SmallText color="primary" className="heading">
              {__('Manage your account and payment cards')}
            </SmallText>
            <Spacer size={2} />
            <IonList className="account-inputs">
              <IonItem lines="none" className="account-ion-item">
                <IonLabel position="floating">{__('First name')}</IonLabel>
                <IonInput
                  mask={nameInputMask}
                  className="account-input"
                  autocapitalize="words"
                  guide={false}
                  onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                  required={true}
                  type="text"
                  pattern="text"
                  inputMode="text"
                  value={first_name}
                />
              </IonItem>
              <IonItem lines="none" className="account-ion-item">
                <IonLabel position="floating">{__('Last name')}</IonLabel>
                <IonInput
                  mask={nameInputMask}
                  className="account-input"
                  autocapitalize="words"
                  guide={false}
                  onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                  required={true}
                  type="text"
                  pattern="text"
                  inputMode="text"
                  value={last_name}
                />
              </IonItem>
              {/* <div className="date-picker-wrapper">
                <label
                  className={`date-picker-label date-picker-label--${newClass}`}
                  htmlFor="demo-non-form"
                >
                  {__("Date Birth")}
                </label>
                <DatePicker
                  className="data-picker-input"
                  display="bottom"
                  max={yesterday}
                  setText={__("Done")}
                  cancelText={__("Cancel")}
                  lang={profile.locale}
                  value={birthday}
                  onSet={(e, a) =>
                    this.handleInput("birthday", a.element.value)
                  }
                  dateFormat="dd/mm/yy"
                  onBeforeShow={() => {
                    this.props.dispatch(beforeShowTimePicker());
                  }}
                  onClose={() => {
                    this.props.dispatch(beforeCloseTimePicker());
                    this.handlePeriodicalSaga(true);
                  }}
                  onShow={() => this.handlePeriodicalSaga(false)}
                />
              </div> */}
              <IonItem lines="none" className="account-ion-item">
                <IonLabel position="floating">{__('Email')}</IonLabel>
                <IonInput
                  onIonChange={(e) => this.handleInput('email', e.target.value)}
                  clearInput
                  required={true}
                  type="email"
                  pattern="email"
                  inputmode="email"
                  value={email}
                ></IonInput>
                {formErrors.email ? (
                  <FieldError className="field-error" value={__(formErrors.email)} />
                ) : null}
              </IonItem>
              <IonItem lines="none" className="account-ion-item">
                <IonLabel position="floating">{__('Phone number')}</IonLabel>
                <IonInput
                  onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                  clearInput
                  required={false}
                  type="tel"
                  pattern="tel"
                  inputmode="tel"
                  value={mobile}
                ></IonInput>
                {formErrors.mobile ? (
                  <FieldError className="field-error" value={__(formErrors.mobile)} />
                ) : null}
              </IonItem>
              {languages && languages.length > 1 ? (
                <>
                  <label
                    className={`select-picker-label select-picker-label--${newClass}`}
                    htmlFor="demo-non-form"
                  >
                    {__('Language Preference')}
                  </label>
                  <SelectOption
                    display="center"
                    onSet={(e, a) => this.handleLanguageInput(e, a)}
                    data={this.formatDataForSelect(getConfig().localization.supportedLocales)}
                    label="Location"
                    value={locale}
                    inputStyle="box"
                    placeholder={__('Select one')}
                    cancelText={__('Cancel')}
                    setText={__('OK')}
                    disabled={this.props.isShowTimePicker ? true : false}
                    onClose={() => {
                      this.handlePeriodicalSaga(true);
                    }}
                    onShow={() => this.handlePeriodicalSaga(false)}
                  />
                </>
              ) : null}
              <Spacer size={1} />
              <NormalText className="account-checkbox-title">
                {__('Communication preferences.')}
              </NormalText>

              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <IonCheckbox
                  color="secondary"
                  className="account-checkbox"
                  slot="start"
                  checked={is_subscribed}
                  onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                />
                <IonLabel className="ion-text-wrap">
                  <NormalText className="medium-text">
                    {__(
                      'Be the ﬁrst to hear about exclusive oﬀers, events and our latest coﬀee, café and product launches.',
                    )}
                  </NormalText>
                </IonLabel>
              </IonItem>
            </IonList>
          </div>

          <div className="top-medium">
            <IonButton
              className="round-button"
              expand="block"
              color="secondary"
              onClick={() => this.handleSave()}
            >
              {__('Save')}
            </IonButton>
            {isWeb() ? (
                <IonButton
                  className="round-button"
                  expand="block"
                  fill="outline"
                  color="secondary"
                  onClick={() => history.push('/cards', { addCardFromAccount: true })}
                >
                  {__('Manage My Payment Cards')}
                </IonButton>
              ) : null
            }
            <IonButton
              expand="block"
              fill="clear"
              color="danger"
              className="underlined"
              onClick={() => this.setState({ closeMyAccount: true })}
            >
              {__('Close my account')}
            </IonButton>
            
          </div>
          <div className="centered-text account-footer">
            <div className="app-version">{__('Powered by')}</div>
            <div>
              <a href="https://5loyalty.com" target="_blank" rel="noopener noreferrer">
                <img src={fiveLoyaltyLogo} />
              </a>
            </div>
            <div className="app-version">{__('App Version:')}</div>
            <div>
              <strong>
                v
                {packageJson.version}
              </strong>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={closeMyAccount}
          onDidDismiss={() => this.setState({ closeMyAccount: false })}
          header={__('Close Your Account')}
          message={__(
            'Are you sure you wish to close your account? Any points and vouchers you have earned will be permanently lost',
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ closeMyAccount: false }),
            },
            {
              text: __('OK'),
              handler: () => this.props.dispatch(deleteUser(profile.user_token)),
            },
          ]}
        />
        <IonAlert
          isOpen={isProfileModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isProfileModalOpen', false))}
          header={__('Success')}
          message={__('Profile is updated.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal('isProfileModalOpen', false)),
            },
          ]}
        />
        <IonAlert
          isOpen={deleteAvatarImageAlert}
          onDidDismiss={() => this.handleAvatarDeleteAlert(false)}
          header={__('Remove')}
          message={__('Do you want to remove profile image.')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleAvatarDeleteAlert(false),
            },
            {
              text: __('Remove'),
              handler: () => this.removeProfileImage(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, isProfileModalOpen } = state.profile;
  const { isShowTimePicker } = state.restaurants;
  return {
    auth,
    profile,
    isProfileModalOpen,
    isShowTimePicker,
  };
};

export default connect(stateToProps)(withTranslation(Account));
