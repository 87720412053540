import React, { Component } from 'react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { IonButton } from '@ionic/react';
import { getConfig } from '../../appConfig';
import crowdfundingLogo from '../../assets/images/logo/crowdcube-logo.png';

class Invest extends Component {

  render() {
    const { __ } = this.props;
    const { invest } = getConfig();
    const { 
      hideInvestButton, 
      investButtonText, 
      investSubtitle, 
      investText, 
      investTitle, 
      investTopText 
    } = invest
    return (
      <Loading>
        <Layout color="white" headerTitle={__('Social Media')} hideSecondToolbar={true}>
          <div className="header-wrapper">
            <Title>{__(investTitle)}</Title>
            <NormalText color="primary" className="heading thiner-text">
              {__(investTopText)}
            </NormalText>
          </div>
          { !hideInvestButton && (
            <>
              <Spacer size={2} />
              <IonButton
                className="round-button invest-button uppercase"
                expand="block"
                color="secondary"
                onClick={() => invest.investPage ? window.open(invest.investPage, '_system', 'location=yes') : null}
              >
                {`${__(investButtonText)}`}
              </IonButton>
            </>
          )}
          <Spacer size={2} />
          {
            investSubtitle && (
              <>
                <NormalText color="primary" className="heading thiner-text bold pre-line">
                  {__(investSubtitle)}
                </NormalText>
                <Spacer/>
              </>
            )
          }
          <NormalText color="primary" className="heading thiner-text pre-line">
            {__(investText)}
          </NormalText>
          <Spacer/>
          <Spacer/>
          <div className='invest-logo'>
            <img src={crowdfundingLogo} />
            <Spacer/>
            <SmallText>
              {__(`Capital at risk.`)}
            </SmallText>
          </div>
        </Layout>
      </Loading>
    );
  }
}

export default (withTranslation(Invest));
