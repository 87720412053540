import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Account from './screens/account';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import Checkout from './screens/checkout';
import ReferAFriend from './screens/referAFriend';
import DeliveryOptions from './screens/delivery-options';
import ClickAndCollect from './screens/clickAndCollect';
import ItemDetails from './screens/itemDetails';
import OrderCompleted from './screens/orderCompleted';
import OrderSummary from './screens/orderSummary';
import AllergenInformation from './screens/allergenInformation';
import OrderToTable from './screens/orderToTable';
import SocialMedia from './screens/social';
import Loyalty from './screens/loyalty';
import ContactDetails from './screens/contactDetails';
import Feedback from './screens/feedback';
import Vouchers from './screens/vouchers';
import Locations from './screens/locations';
import Redirection from './screens/redirection';
import ApplyVouchers from './screens/applyVouchers';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import invest from './screens/invest';
import loginIcon from './assets/images/icons/login.svg';
import startNewOrderIcon from './assets/images/icons/start-new-order.svg';
import loyaltyIcon from './assets/images/icons/loyalty.svg';
import referFriendIcon from './assets/images/icons/refer-a-friend.svg';
import homeIcon from './assets/images/icons/home.svg';
import myRewardsIcon from './assets/images/icons/my-rewards.svg';
import ourHousesIcon from './assets/images/icons/our-houses.svg';
import shopWatchHouseIcon from './assets/images/icons/shop-watchhouse.svg';
import myAccountIcon from './assets/images/icons/my-account.svg';
import orderHistoryIcon from './assets/images/icons/order-history.svg';
import feedbackIcon from './assets/images/icons/feedback.svg';
import connectWithUsIcon from './assets/images/icons/connect.svg';
import crowdfundingIcon from './assets/images/icons/crowdfunding.svg';
import menuIcon from './assets/images/menu-image.svg';
import api from './lib/api';

let navConfig;
const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();
  const singleDeliveryOption = await getSingleDeliveryOption();
  const { hasCampaignManager, hasOrdering, hasLoyalty } = config.appType;
  const hasInvest = config.invest && config.invest.hasInvest;
  const customFeedbackLink = config?.general?.customFeedbackLink || false
  navConfig = {
    routes: [
      ...(hasInvest
        ? [
          {
            label: 'We’re crowdfunding',
            component: invest,
            path: '/invest',
            icon: crowdfundingIcon,
            protected: false,
          },
        ]
        : []),
      {
        label: 'Home',
        path: '/dashboard',
        icon: homeIcon,
        component: Dashboard,
        exact: true,
        default: !isWebConfig(),
        notInDrawer: isWebConfig(),
      },
      {
        label: 'Menu',
        path: '/order',
        component: Order,
        icon: menuIcon,
        exact: true,
        default: isWebConfig(),
        notInDrawer: !isWebConfig(),
      },
      ...(hasOrdering && [
        {
          label: singleDeliveryOption ? singleDeliveryOption.label : '',
          path: '/click-and-collect',
          component: ClickAndCollect,
          protected: false,
          notInDrawer: true,
        },
        {
          label: singleDeliveryOption ? singleDeliveryOption.label : '',
          path: '/order-to-table',
          component: OrderToTable,
          protected: false,
          notInDrawer: true,
        },
        {
          label: 'Start new order',
          component: DeliveryOptions,
          icon: startNewOrderIcon,
          path: '/delivery-options',
          protected: false,
        },
      ]),
      ...(hasLoyalty
        ? [
          {
            label: 'Loyalty',
            component: Loyalty,
            icon: loyaltyIcon,
            path: '/loyalty',
            protected: true,
            state: { tab: 'points' },
          },
        ]
        : []),
      ...(hasCampaignManager
        ? [
          {
            label: 'My rewards',
            component: Vouchers,
            path: '/vouchers',
            icon: myRewardsIcon,
            protected: true,
          },
        ]
        : []),
      {
        label: 'Our houses',
        path: '/locations',
        component: Locations,
        icon: ourHousesIcon,
        protected: false,
      },
      {
        label: 'Shop WatchHouse',
        path: '/shop',
        component: Redirection,
        location: 'https://watchhouse.com/collections/all',
        icon: shopWatchHouseIcon,
        protected: false,
      },
      {
        label: 'My account',
        path: '/account',
        component: Account,
        icon: myAccountIcon,
        protected: true,
      },
      {
        label: 'Order history',
        path: '/history',
        component: History,
        icon: orderHistoryIcon,
        component: History,
        protected: true,
      },
      ...(customFeedbackLink
        ? [
          {
            label: 'Feedback',
            component: Feedback,
            icon: feedbackIcon,
            protected: true,
            isLink:true,
            path:customFeedbackLink
          },
        ]
        : [

          {
            label: 'Leave feedback',
            path: '/feedback',
            component: Feedback,
            icon: feedbackIcon,
            protected: true,
          },
        ]),
    
      {
        label: 'Connect with us',
        component: SocialMedia,
        path: '/social-media',
        icon: connectWithUsIcon,
        protected: true,
      },
      ...(hasOrdering
        ? [{ label: 'Checkout', path: '/checkout', notInDrawer: true, protected: true }]
        : []),
      ...(hasCampaignManager
        ? [
          {
            label: 'Refer a friend',
            component: ReferAFriend,
            icon: referFriendIcon,
            path: '/refer-a-friend',
            protected: true,
          },
        ]
        : []),
    ],
    authRoutes: [
      { label: 'Login', icon: loginIcon, path: '/login', component: Login, fn: 'login' },
      { label: 'Logout', icon: loginIcon, path: '/logout', fn: 'logout' },
    ],
    additionalRoutes: [
      { label: 'Terms & Conditions', path: '/terms', component: Terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy },
      { label: 'Faq & Support', path: '/faq', component: Faq },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/checkout', component: Checkout }] : []),
      { path: '/order', component: Order },
      { path: '/item-details', component: ItemDetails },
      { path: '/order-completed', component: OrderCompleted },
      { path: '/order-summary', component: OrderSummary },
      { path: '/allergen-information', component: AllergenInformation },
      { path: '/contact-details', component: ContactDetails },
      { path: '/history-details', component: HistoryDetails },
      { path: '/apply-vouchers', component: ApplyVouchers },
    ],
  };

  return navConfig;
};
export default navConfig;
